<template>
  <div class="p-grid p-px-3" style="color: white">
    <div class="p-col-12 p-md-5">
      <client-running />
    </div>

    <div class="p-col-12 p-md-7 p-pt-3">
      <div class="box p-shadow-4 p-text-bold p-p-2">
        Next Draw. Remember to buy your numbers early!
      </div>
      <div class="card2 p-mt-3 p-px-3 p-shadow-4">
        <div class="p-grid p-pt-2 p-mb-0 p-pb-0">
          <div class="p-col-2 p-text-center box p-text-bold p-p-2 not-round">
            Draw
          </div>
          <div class="p-col-2 p-text-center box p-text-bold p-p-2 not-round">
            Prize
          </div>
          <div class="p-col-3 p-text-center box p-text-bold p-p-2 not-round">
            Day
          </div>
          <div
            class="p-col-2 p-md-3 p-text-center box p-text-bold p-p-2 not-round"
          >
            Date
          </div>
          <div
            class="p-col-3 p-md-2 p-text-center box p-text-bold p-p-2 not-round"
          >
            Result
          </div>
        </div>
        <!--table content-->
        <div v-if="draw_data != null" class="p-mt-0 p-pb-2">
          <div
            class="p-grid p-mt-0 p-text-center menu-item"
            v-for="(data, index) in draw_data"
            :key="index"
          >
            <div class="p-col-2 bordered">{{ data.draw_no }}</div>
            <div class="p-col-2 bordered">1</div>
            <div class="p-col-3 bordered">{{ data.draw_date_name }}</div>
            <div class="p-col-2 p-md-3 bordered">{{ data.draw_date }}</div>
            <div class="p-col-3 p-md-2 bordered">{{ data.draw_prize1 }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientRunning from "./ClientRunning";
export default {
  components: {
    ClientRunning,
  },
  data() {
    return {
      draw_data: null,
      draw_no: "",
      draw_date: "",
      draw_prize1: "",
      draw_prize2: "",
      draw_prize3: "",
      draw_started1: "",
      draw_started2: "",
      draw_started3: "",
      draw_started4: "",
      draw_started5: "",
      draw_started6: "",
      draw_consolation1: "",
      draw_consolation2: "",
      draw_consolation3: "",
      draw_consolation4: "",
      draw_consolation5: "",
      draw_consolation6: "",
      acak: this.$store.state.acak,
      all_draw_data:null,
    };
  },
  methods: {
    async getResult() {
      await this.$http.get("/result").then((r) => {
        this.all_draw_data=r.data
        if (this.acak) {
          this.draw_data = r.data.splice(1, 11);
        } else {
          if (
            new Date().toDateString() ==
            new Date(r.data[0].draw_date).toDateString()
          ) {
            this.draw_data = r.data.splice(1, 11);
          } else {
            this.draw_data = r.data.splice(0, 10);
          }
        }
        // this.setResult(r.data[0]);
      });
    },
    getDay(tanggal) {
      var hari = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      var dt = new Date(tanggal);
      return hari[dt.getDay()];
    },
    setResult(data) {
      this.draw_no = data.draw_no;
      this.draw_date = data.draw_date;
      this.draw_prize1 = data.draw_prize1;
      this.draw_prize2 = data.draw_prize2;
      this.draw_prize3 = data.draw_prize3;
      this.draw_started1 = data.draw_started1;
      this.draw_started2 = data.draw_started2;
      this.draw_started3 = data.draw_started3;
      this.draw_started4 = data.draw_started4;
      this.draw_started5 = data.draw_started5;
      this.draw_started6 = data.draw_started6;
      this.draw_consolation1 = data.draw_consolation1;
      this.draw_consolation2 = data.draw_consolation2;
      this.draw_consolation3 = data.draw_consolation3;
      this.draw_consolation4 = data.draw_consolation4;
      this.draw_consolation5 = data.draw_consolation5;
      this.draw_consolation6 = data.draw_consolation6;
    },
  },
  mounted() {
    this.getResult();
  },
  watch:{
    '$store.state.acak' :function(val){
      if(val==false){
        this.draw_data.pop()
        this.draw_data.unshift(this.all_draw_data[0])
      }
    }
  }
};
</script>
